<template>
  <div class="proposal__wrap">
    <div class="section shadow filter__wrap">
      <div class="flex">
        <el-form ref="elFormDom" inline :model="table.params" size="small">
          <el-form-item label="类别" prop="type">
            <el-select v-model="table.params.type" placeholder="请选择" clearable>
              <el-option label="全部" value=""></el-option>
              <el-option label="奖金" :value="1"></el-option>
              <el-option label="假期" :value="2"></el-option>
              <el-option label="福利" :value="3"></el-option>
              <el-option label="其他" :value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="福利名称" prop="name">
            <el-input clearable v-model.trim="table.params.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select v-model="table.params.status" placeholder="请选择" clearable>
              <el-option label="全部" value=""></el-option>
              <el-option label="开启" :value="1"></el-option>
              <el-option label="关闭" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询 </el-button>
            <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div style="margin-bottom:10px;">
      <el-button l-button type="primary" icon="el-icon-plus" size="small" @click="showEdit">添加 </el-button>
    </div>
    <!-- 表格 -->
    <div class="table__wrap">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="field"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showOverflowTooltip="true"
        :showSelect="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
        <template v-slot:status="{row}">
          <el-switch
            v-model="row.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="2"
            @change="handleStatus(row)">
          </el-switch>
        </template>
        <template v-slot:action="{row}">
          <el-button type="text" size="mini" icon="el-icon-edit" @click="showEdit(row)">编辑</el-button> 
        </template>
      </VTable> 
    </div>

    <edit ref="edit" @refresh="getTable"></edit>

  </div>
</template>

<script>
import VTable from '@/components/VTable'
import Edit from './components/Edit.vue'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'ReportList',
  mixins:[mixinTable],
  components: {
    VTable,
    Edit,
  },
  data() {
    return {
      field: [
        { name: "type_name", label: "类别", hidden: false },
        { name: "name", label: "福利名称", hidden: false },
        { name: "create_time", label: "创建时间", hidden: false },  
        { name: "status", label: "状态", hidden: false },
        { name: "action", label: "操作", width: "160", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          name: '',
          status: '', //状态 1:启用 2:禁用
          type: '', // 类型 1:奖金，2:假期，3:福利, 4:其他
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      areaArr: [],
      multipleSelection: [],
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/weal/list',  {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 切换状态
    handleTabs() {
      this.table.params.page = 1;
      this.table.data = [];
      this.getTable();
    },
    // 重置查询条件
    reset() {
      this.$refs.elFormDom.resetFields()
      this.table.params.page = 1;
      this.getTable();
    },
    // 创建、编辑
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if(!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 下架
    handleOption(row) {
      let str = this.table.params.status == 1 ? '下架' : '上架'
      this.$confirm(`请确认${str}操作？`,'提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/admin/weal/operation', {id: row.id}).then(res => {
          if(res.code == 1) {
            this.getTable();
            this.$message.success('操作成功！')
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {})
    },
    // 状态
    handleStatus(row) {
      this.$http.post('/admin/weal/operation', {id:row.id}).then(res => {
        if(res.code === 1) {
          this.$message.success('操作成功！')
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => {
        this.getTable();
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .flex {
    display: flex;
  }
  .proposal__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    position: relative;

    .filter__wrap {
      padding: 10px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    .table__wrap {
      flex: 1;
      padding: 0;
      box-sizing: border-box;
    }
  }
</style>